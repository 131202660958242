<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      devicelist: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",
      jurisdiction: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      loading: true,
      block: false,
      noticelist: [],
      systemtype: 0,
      multipleSelection: [],
      loadingtext: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.devicelist.length;
    },
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.devices.lists");
    that.items = [
      {
        text: that.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.devices.lists"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    if (localStorage.getItem("notice")) {
      that.noticelist = JSON.parse(localStorage.getItem("notice"));
    }
    that.getdevicelist();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "notice") {
        wsdata["time"] = Date.parse(new Date());
        wsdata["read"] = 0;
        that.noticelist.unshift(wsdata);
        localStorage.setItem("notice", JSON.stringify(that.noticelist));

        if (wsdata.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.device", { device: wsdata.edid }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t("global.notice.server." + wsdata.service),
              }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
            duration: 0,
          });
        } else if (wsdata.type2 === "stock") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message: that.$t("global.notice.stock", {
              edid: wsdata.edid,
              stock: wsdata.stock,
            }),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "sensor") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message: that.$t("global.notice.sensor", {
              edid: wsdata.edid,
              num: wsdata.num,
            }),
            type: wsdata.status,
          });
        }
      }
    };
    that.getsysteminfo();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdevicelist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "deviceslist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.devicelist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deletedevice(id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deletedevicetip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "devicedelete",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getdevicelist();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    devicecheckout(id, count = 1, index = 1) {
      var that = this;
      console.log(id, count, index);
      that.loading = true;
      if (index > 0) {
        that.loadingtext = that.$t("device.list.checkouts", {
          index: index,
          count: that.multipleSelection.length,
        });
      } else {
        that.loadingtext = "";
      }

      that.$axios
        .post(
          that.apiuri,
          {
            action: "barcheckout",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (count === index) {
            that.loading = false;
          }
          if (!response.data) {
            that.$message.error(that.$t("global.delete.500"));
          } else if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if (response.data.status == 404) {
            that.$message.error(that.$t("device.lists.offline"));
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openbeer(id) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "openbar",
            id: id,
            route: [true, true, true, true, true, true],
            reset: true,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (!response.data) {
            that.$message.error(that.$t("global.delete.500"));
          } else if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if (response.data.status == 404) {
            that.$message.error(that.$t("device.lists.offline"));
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getselectable(row, index) {
      if (row.beermode == 2) {
        return true;
      } else {
        return false;
      }
    },
    renderHeader(h) {
      return (
        <div>
          <el-button
            type="primary"
            style="padding:3px;margin-left:5px"
            size="mini"
            round
            //加入点击事件---------
            onClick={() => this.bathopenbar()}
            //---------------------
          >
            一键开台
          </el-button>
          <el-button
            type="primary"
            style="padding:3px;margin-left:5px"
            size="mini"
            round
            //加入点击事件---------
            onClick={() => this.bathcheckout()}
            //---------------------
          >
            结账
          </el-button>
        </div>
      );
    },
    bathopenbar() {
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          this.openbeer(this.multipleSelection[i].id);
        }
      }
    },
    bathcheckout() {
      var that = this;
      if (that.multipleSelection.length > 0) {
        that.loading = true;
        let count = that.multipleSelection.length;
        for (let i = 0; i < count; i++) {
          let index = i + 1;
          if (i == 0) {
            that.devicecheckout(that.multipleSelection[i].id, count, index);
          } else {
            let ids = that.multipleSelection[i].id;
            let timeout = 1000 * index;
            setTimeout(function () {
              that.devicecheckout(ids, count, index);
            }, timeout);
          }
        }
      }
    },
    async sleep(numberMillis) {
      var now = new Date();
      var exitTime = now.getTime() + numberMillis;
      while (true) {
        now = new Date();
        if (now.getTime() > exitTime) return;
      }
    },
    changeSort (val){},
  },
  computed: {
    searchindeviceslist() {
      var datas = this.devicelist;
      if (this.devicesearch) {
        datas = datas.filter((value) => {
          return (
            !this.devicesearch ||
            value.ED_id.toLowerCase().includes(
              this.devicesearch.trim().toLowerCase()
            ) ||
            !this.devicesearch ||
            value.device_sn
              .toLowerCase()
              .includes(this.devicesearch.trim().toLowerCase())
          );
        });
      }
      if (this.selectdevicestatus != -1) {
        switch (this.selectdevicestatus) {
          case 0:
            datas = datas.filter((value) => {
              return value.status == 0;
            });
            break;
          case 1:
            datas = datas.filter((value) => {
              return value.online == 1;
            });
            break;
          case 5:
            datas = datas.filter((value) => {
              return value.status == 5;
            });
            break;
          case 9:
            datas = datas.filter((value) => {
              return value.online == 0;
            });
            break;
        }
      }
      return datas;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row col-md-12">
              <div class="col-md-12" style="padding: 0">
                <input
                  type="search"
                  v-model="devicesearch"
                  class="form-control"
                  :placeholder="$t('device.lists.search')"
                />
              </div>
            </div>
            <div
              class="row col-md-12"
              v-loading="loading"
              :element-loading-text="loadingtext"
            >
              <el-table
                ref="multipleTable"
                :data="searchindeviceslist"
                tooltip-effect="dark"
                @sort-change="changeSort"
                :default-sort="{prop: 'ED_id', order: 'ascending'}"
                @selection-change="handleSelectionChange"
              >
                <el-table-column :render-header="renderHeader">
                  <el-table-column
                    type="selection"
                    :selectable="getselectable"
                    width="55"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="ED_id"
                    :label="$t('device.lists.id')"
                    width="140"
                    sortable
                    :sort-orders="['ascending', 'descending']"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    :label="$t('device.lists.name')"
                    width="150"
                  >
                  </el-table-column>
                  <el-table-column
                    :label="$t('device.lists.version')"
                    width="120"
                  >
                    <template slot-scope="scope">{{
                      $t("version." + scope.row.version)
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('device.lists.status')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <label
                        class="badge badge-warning"
                        v-if="scope.row.status == 0"
                        >{{ $t("device.lists.overdue") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="scope.row.status == 5"
                        >{{ $t("device.lists.maintain") }}</label
                      >
                      <label
                        class="badge badge-success"
                        v-if="scope.row.status == 1 && scope.row.online == 1"
                        >{{ $t("device.lists.ok") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="scope.row.status == 1 && scope.row.online == 0"
                        >{{ $t("device.lists.offline") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 31
                        "
                        >{{ $t("device.error.error31") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 32
                        "
                        >{{ $t("device.error.error32") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 33
                        "
                        >{{ $t("device.error.error33") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 34
                        "
                        >{{ $t("device.error.error34") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 35
                        "
                        >{{ $t("device.error.error35") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 36
                        "
                        >{{ $t("device.error.error36") }}</label
                      >
                      <label
                        class="badge badge-danger"
                        v-if="
                          scope.row.status == 1 && scope.row.error_code == 37
                        "
                        >{{ $t("device.error.error37") }}</label
                      >
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('device.lists.action')">
                    <template slot-scope="scope">
                      <router-link
                        :to="{
                          path: '/device/devicedetail',
                          query: { id: scope.row.id },
                        }"
                      >
                        <el-button size="mini" type="primary" round>{{
                          $t("device.lists.info")
                        }}</el-button>
                      </router-link>
                      <router-link
                        v-if="systemtype == 1 || scope.row.beermode == 2"
                        :to="{
                          path: '/device/baropen',
                          query: { id: scope.row.id },
                        }"
                      >
                        <el-button size="mini" type="success" round>{{
                          $t("device.button.openstage")
                        }}</el-button>
                      </router-link>
                      <el-button
                        size="mini"
                        type="primary"
                        v-if="systemtype == 1 || scope.row.beermode == 2"
                        @click="openbeer(scope.row.id)"
                        round
                        >{{ $t("device.button.onclickopenbar") }}</el-button
                      >

                      <router-link
                        v-if="systemtype == 1 || scope.row.beermode == 2"
                        :to="{
                          path: '/device/barclose',
                          query: { id: scope.row.id },
                        }"
                      >
                        <el-button type="success" size="mini" round>{{
                          $t("device.button.closestage")
                        }}</el-button>
                      </router-link>

                      <el-button
                        type="primary"
                        size="mini"
                        round
                        v-if="systemtype == 1 || scope.row.beermode == 2"
                        @click="devicecheckout(scope.row.id)"
                      >
                        {{ $t("device.button.checkout") }}</el-button
                      >
                      <el-button
                        type="danger"
                        size="mini"
                        round
                        v-if="!block"
                        @click="deletedevice(scope.row.id)"
                        >{{ $t("device.lists.delete") }}</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>